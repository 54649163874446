import React from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { useTranslation } from 'react-i18next';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import { Helmet } from "react-helmet";

function Cinematheque() {

    const { t } = useTranslation();
    
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Cinematheque Page",
        "description": "Monthly curated selection of short and feature films, celebrating Canadian talent and cultural diversity.",
        "url": `${window.location.origin}/cinematheque`,
        "publisher": {
        "@type": "Organization",
        "name": "Cinewoo",
        "url": "https://cinewoo.com",
        "logo": {
            "@type": "ImageObject",
            "url": "https://cinewoo.com/logo.png", // Replace with actual logo URL
            "width": 250,
            "height": 60
        }
        },
        "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
            "@type": "ListItem",
            "position": 1,
            "name": "Cinematheque",
            "item": `${window.location.origin}/cinematheque`
            }
        ]
        }
    };

    return (
        <>

            <Helmet>
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>
            
            {/*/--- navbar ---/*/}
            <Navbar />

            {/*/--- banner section ---/*/}
            <section className='home-banner video-hub-banner'>
                <div className='video-banner-layer'>
                    <CContainer className="h-100-p">
                        <CRow className="align-items-center h-100-p">
                            <CCol md={12}>
                                <div className='home-banner-wrapper text-center'>
                                    <h2 className='typing-demo2'>
                                        {t('Stay Tuned')}
                                    </h2>
                                </div>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </section>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default Cinematheque;
