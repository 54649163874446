import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//*//----- auth pages -----//*//
import Login from './auth/Login';
import SignUp from './auth/SignUp';
import ForgotPassword from './auth/ForgotPassword';
import UpdatePassword from './auth/UpdatePassword';

//*//----- landing pages -----//*//
import NewTalent from './pages/NewTalent';
import CastingCalls from './pages/CastingCalls';
import RoleDescription from './pages/RoleDescription';
import CastingAudition from './pages/CastingAudition';
import FilmPitchingBooth from './pages/FilmPitchingBooth';
import SuccessStories from './pages/SuccessStories';
import IndustryProfiles from './pages/IndustryProfiles';
import Profile from './pages/Profile';
import EditProfile from './pages/EditProfile';
import CastingCallsCreation from './pages/CastingCallsCreation';
import UserFilmPitchingBooth from './pages/UserFilmPitchingBooth';
import Membership from './pages/Membership';
import Subscription from './pages/Subscription';
import Home from './pages/Home';
import Auditions from './pages/Auditions';
import VideoHub from './pages/VideoHub';
import Cinematheque from './pages/Cinematheque';
import PitchProfile from './pages/PitchProfile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ContactUs from './pages/ContactUs';
import UserChatScreen from './Chat/UserChatScreen';
import ChatThreads from './Chat/ChatThreads';
import Notifications from './notifications/Notifications';
import ClosedCastingCalls from './pages/ClosedCastingCalls';
import Settings from './pages/Settings';
import EmailVerify from './email-templates/EmailVerify';
import WelcomeEmail from './email-templates/WelcomeEmail';
import ResetPasswordEmail from './email-templates/ResetPasswordEmail';
import PrivateRoute from './profileCompleteLayout/profileCompleteLayout';

function Routers() {
  const { t } = useTranslation();

  return (
    <>
      <Routes>
        {/* Auth pages */}
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password/:tokenId' element={<UpdatePassword />} />

        {/* Public pages */}
        <Route path='/' element={<Home />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-use' element={<TermsOfUse />} />
        <Route path='/contact-us' element={<ContactUs />} />


        <Route path='/verify-email' element={<EmailVerify />} />
       
        {/* Private pages (protected by PrivateRoute) */}
        <Route path='/casting-room' element={<PrivateRoute><NewTalent /></PrivateRoute>} />
        <Route path='/casting-calls' element={<PrivateRoute><CastingCalls /></PrivateRoute>} />
        <Route path='/closed-casting-calls' element={<PrivateRoute><ClosedCastingCalls /></PrivateRoute>} />
        <Route path='/role-description/:id' element={<PrivateRoute><RoleDescription /></PrivateRoute>} />
        <Route path='/casting-audition/:castingCallId' element={<PrivateRoute><CastingAudition /></PrivateRoute>} />
        <Route path='/pitch-profile/:castingCallId' element={<PrivateRoute><PitchProfile /></PrivateRoute>} />
        <Route path='/film-booth' element={<PrivateRoute><FilmPitchingBooth /></PrivateRoute>} />
        <Route path='/success-stories' element={<PrivateRoute><SuccessStories /></PrivateRoute>} />
        <Route path='/industry-profiles' element={<PrivateRoute><IndustryProfiles /></PrivateRoute>} />
        <Route path='/user-profile/:userid' element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path='/video-hub' element={<PrivateRoute><VideoHub /></PrivateRoute>} />
        <Route path='/cinematheque' element={<PrivateRoute><Cinematheque /></PrivateRoute>} />
        <Route path='/chat-screen/:userid' element={<PrivateRoute><UserChatScreen /></PrivateRoute>} />
        <Route path='/chat-threads' element={<PrivateRoute><ChatThreads /></PrivateRoute>} />
        <Route path='/notifications' element={<PrivateRoute><Notifications /></PrivateRoute>} />
        <Route path='/subscription' element={<PrivateRoute><Subscription /></PrivateRoute>} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/edit-user-profile' element={<PrivateRoute><EditProfile /></PrivateRoute>} />
        <Route path='/casting-calls-creation' element={<PrivateRoute><CastingCallsCreation /></PrivateRoute>} />
        <Route path='/user-film-booth' element={<PrivateRoute><UserFilmPitchingBooth /></PrivateRoute>} />
        <Route path='/user-auditions/:id' element={<PrivateRoute><Auditions /></PrivateRoute>} />
        <Route path='/membership' element={<PrivateRoute><Membership /></PrivateRoute>} />
      </Routes>
    </>
  );
}

export default Routers;
