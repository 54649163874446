import Routers from './Routers';
import { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';

// styles
import './App.css';
import './assets/css/style.css';
import './assets/css/auth.css';
import './assets/css/responsive.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import { Helmet } from 'react-helmet'; // Import Helmet for schema markup


function App() {
  const [loading, setLoading] = useState(true);
  const recaptchaSiteKey = process.env.REACT_APP_SITE_KEY;

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Cinewoo Home Page",
    "description": "Welcome to Cinewoo, the ultimate platform connecting Canadian filmmakers, actors, and industry professionals. Explore our innovative features and join the community today.",
    "url": `${window.location.origin}/`,
    "publisher": {
      "@type": "Organization",
      "name": "Cinewoo",
      "url": "https://cinewoo.com",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cinewoo.com/logo.png", // Replace with actual logo URL
        "width": 250,
        "height": 60
      }
    },
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": `${window.location.origin}/`
        }
      ]
    }
  };

  return (
    <>
      {/* Inject structured data into the head */}
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>

      <Router>
        <Routers />
      </Router>

      {/* React Toastify */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      {/* ReCAPTCHA */}
      <div className='w-100 d-flex justify-content-center'>
        <ReCAPTCHA sitekey={`${recaptchaSiteKey}`} theme={'dark'} size="invisible" />
      </div>
    </>
  );
}

export default App;
