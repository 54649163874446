import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import {  toast } from 'react-toastify';

function PrivateRoute({ children }) {
  const [isAuth, setIsAuth] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    const profileComplete = localStorage.getItem('profileComplete') === 'complete';

    if (token) {
      setIsAuth(true);
      setIsProfileComplete(profileComplete);
      if(user?.email_verified){
        setIsVerified(true);
        console.log('email verified : ',user.email_verified);
      }
    }
    setLoading(false); // Set loading to false after the checks
  }, []);

  // Effect to show toast only once when profile is incomplete
  useEffect(() => {
    if (!loading && isAuth && !isProfileComplete) {
      toast.warn('Please complete your profile!');
    }
  }, [loading, isAuth, isProfileComplete]);

  if (loading) {
    return <div>Loading...</div>; // Loading state while fetching/checking local storage
  }

  if (!isAuth) {
    // If not authenticated, allow access to public routes
    return children;
  }

  if(!isVerified){
    return <Navigate to="/login" />;
  }

  if (!isProfileComplete) {
    // If authenticated but profile not complete, redirect to settings
    return <Navigate to="/settings" />;
  }

  // If authenticated and profile complete, allow access to private routes
  return children;
}

export default PrivateRoute;
